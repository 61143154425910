import React, { useEffect } from 'react';
import FootballImg from '../../assets/football.png';
import BottleImg from '../../assets/bottle.png';
import './ScarInChildren.scss';
import SectionFourth from '../../components/SectionFourth/SectionFourth';

function ScarInChildren() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="page-container">
      <div className="yellow-line">
        <h3>Шрамы у детей</h3>
      </div>
      <div className="page-text">
        <article>
          <p>
            <strong>Детство –</strong> это пора активного познания окружающего
            мира, и дети с огромным энтузиазмом открывают для себя новые
            горизонты. К сожалению, порой последствия этих открытий – травмы,
            раны, ссадины, порезы и ожоги. И многие из них оставляют шрамы,
            которые навсегда могут сохраниться на лице или теле ребенка.По
            данным Минздрава детский травматизм в Беларуси насчитывает более 150
            тысяч случаев в год.{' '}
          </p>
          <strong>Что важно:</strong>
          <p>
            Видимый шрам может накладывать негативный отпечаток на
            психологическое состояние ребенка, его принятие себя и адаптацию в
            социуме, в котором он может встретить различную реакцию на свой шрам
            – от удивления и неудобных вопросов, до открытой негативной оценки.
            Все это может привести к психологической травме, замкнутости,
            проблемам с самооценкой и, как результат, проблемам к построению
            отношений и самореализации по мере взросления.
          </p>
          <p>
            В пубертатном периоде повреждения кожи при наличии склонности могут
            заканчиваться образованием так называемых келоидов. Такие рубцы
            образуются преимущественно в определенных областях тела, например, в
            области груди, плеч или мочек ушей.
          </p>
          <p>
            Кроме того, большой проблемой пубертатного возраста является акне,
            тяжелое течение которого часто оставляет видимые шрамы на лице в
            виде постакне, которые также крайне негативно сказываются на
            самооценке в молодом возрасте.
            <br></br>
            <small>
              Источник:
              <br></br>
              <a
                target={'_blank'}
                href="https://pubmed.ncbi.nlm.nih.gov/34705166/"
              >
                1. Impact of Facial Atrophic Acne Scars on Quality of Life: A
                Multi-country Population-Based Survey
              </a>
              <br />
              Jerry Tan, Stefan Beissert, Fran Cook-Bolden, Rajeev Chavda, Julie
              Harper, Adelaide Hebert, Edward Lain, Alison Layton, Marco
              Rocha, Jonathan Weiss, Brigitte Dréno
              <br />
              <a
                target={'_blank'}
                href="https://www.springer.com/journal/40257"
              >
                2. American Journal of Clinical Dermatology (2022) 23:115–123
              </a>
            </small>
          </p>
          <img
            // style={{margin: "50px 0"}}
            src={BottleImg}
            alt=""
          />
          <strong>
            Очень важно объяснять детям последствия действий, которые могут
            привести к травмированию, а у подростков с акне – своевременно
            проводить лечение акне у врача-дерматолога.
          </strong>
          <p>
            Но если неприятность уже случилась и шрам у ребенка остался, не
            стоит думать, что это непоправимо!
          </p>
          <p>
            Помогите своему ребенку избежать образования заметных рубцов –
            начните терапию как можно раньше, посоветовавшись с врачом.
          </p>
          <strong>
            Разрешен к применению у детей препарат №1 для лечения шрамов –
            Контрактубекс®, оказывающий тройное активное действие против шрамов,
            делая их мягкими, гладкими, менее заметными. Контрактубекс®
            выпускается в форме геля.
          </strong>
        </article>
        <article>
          <strong>
            Способ применения Контрактубекс® геля детям, как и взрослым, зависит
            от стадии заживления раны:
          </strong>
          <p>
            <strong>Для свежих рубцов</strong>
            <br />
            Наносить 2-3 раза в день необходимое количество геля и осторожно
            втирать в рубец до полного впитывания в течение минимум 4 недель.
          </p>
          <p>
            <strong>Для застарелых рубцов</strong>
            <br />
            Наносить 3 раза в сутки массирующими движениями. Для усиления
            эффекта можно оставить гель под повязкой на ночь. Срок лечения в
            таком случае составит не менее 6 месяцев. Также возможно совмещение
            лечения с процедурами по рекомендации врача.
            <sup>3</sup>
            <br></br>
            <small>
              <a
                href="https://www.rceth.by/NDfiles/instr/5092_01_03_06_08_11_16_s.pdf"
                target={'_blank'}
              >
                3. Из официальной инструкции по применению препарата
                Контрактубекс®
              </a>
            </small>
          </p>
          <p>
            Для положительного исхода лечения необходимо соблюдать одно важное
            условие. Применение Контрактубекс® геля должно быть систематичным.
            При соблюдении инструкции результат не заставит себя ждать. Ведь
            регенерирующие способности кожи у детей значительно выше, чем у
            взрослых. Это обусловлено повышенной миотической активностью
            керациноцитов в базальном слое эпидермиса. В нижнем ростковом слое
            клетки кожи делятся ускоренными темпами. Поэтому во многих случаях
            при небольших шрамах у ребенка и при должном лечении с помощью
            Контрактубекс® геля можно рассчитывать на отличный результат.
          </p>
        </article>
        <img style={{ width: '100%' }} src={FootballImg} alt="" />
        <article>
          <p>
            Средство легко наносится, быстро впитывается, не пачкает одежду, его
            применение не доставит ребенку дискомфорта.
          </p>
          <strong>Применение в педиатрии</strong>
          <p>
            Данные о применении лекарственного средства в педиатрической
            практике ограничены, данные о применении у детей до 1 года
            отсутствуют. Применение лекарственного средства у детей до 1 года
            противопоказано. Применение лекарственного средства у детей старше 1
            года возможно только после консультации врача в случае, если польза
            от применения превышает возможный риск, во время лечения необходимо
            наблюдение врача.
          </p>
        </article>
      </div>
      <SectionFourth></SectionFourth>
    </div>
  );
}

export default ScarInChildren;
