import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Menu.scss";
import LocationImg from "../../assets/location-white.svg";

function Menu({ toggleBurger, isBurgerOpen }) {
  const navigate = useNavigate()
  return (
    <div className={isBurgerOpen ? "dropdown isOpen" : "dropdown"}>
      <div className="dropdown-inner">
        <div className="dropdown-header"></div>
        <div className="dropdown-body">
          <nav>
            <ul>
              <Link to={""} onClick={() => toggleBurger()}>
                <li>Главная</li>
                <div className="hover-block"></div>
              </Link>
              <Link to={"/about"} onClick={() => toggleBurger()}>
                <li>О препарате</li>
                <div className="hover-block"></div>
              </Link>
              <Link to={"/how-it-works"} onClick={() => toggleBurger()}>
                <li>Как работает препарат?</li>
                <div className="hover-block"></div>
              </Link>
              <Link to={"/scars-in-children"} onClick={() => toggleBurger()}>
                <li>Шрамы у детей</li>
                <div className="hover-block"></div>
              </Link>
              <Link to={'/privacy-policy'} onClick={() => toggleBurger()}><li>Политика конфиденциальности</li><div className="hover-block"></div></Link>
              <Link to={'/terms-of-use'} onClick={() => toggleBurger()}><li>Условия использования</li><div className="hover-block"></div></Link>
              <Link to={'/sitemap'} onClick={() => toggleBurger()}><li>Карта сайта</li><div className="hover-block"></div></Link>
              {/* <Link to={""} onClick={() => toggleBurger()}>
                <li>Уход за кожей</li>
                <div className="hover-block"></div>
              </Link>
              <Link to={""} onClick={() => toggleBurger()}>
                <li>Вопрос-ответ</li>
                <div className="hover-block"></div>
              </Link> */}
                <button
              onClick={() => {
                toggleBurger()
                if (!document.querySelector("section.fourth")) navigate('/')
                setTimeout(() => {
                  window.scroll({
                    top: document.querySelector("section.fourth").offsetTop,
                    behavior: "smooth",
                  })
                }, 0)
              }
              }
            >
            <div className="location-link">
              <img src={LocationImg} alt="" />
              <span>Где купить?</span>
            </div>
                </button>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Menu;
