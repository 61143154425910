import React from 'react'

function FourthSlide() {
  return (
    <div className="slider-body__current-view">
        <div className="slider-body__current-view-left"  style={{paddingRight: 0}}>
            <div className="box">
                <h3>Думай о себе, а не о шрамах</h3>
                <p>Изучите продукт подробнее</p>
                <button onClick={(e) => {e.stopPropagation(); window.scroll({top: document.querySelector('section.second').offsetTop - 100, behavior: 'smooth'})}}>Узнать больше</button>
            </div>
        </div>
    </div>
  )
}

export default FourthSlide