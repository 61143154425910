import React, {useEffect} from "react";
import HowItWorks1 from "../../assets/how-it-works-1.png";
import SectionFourth from "../../components/SectionFourth/SectionFourth";
import "./HowItWorks.scss";

function HowItWorks() {

    useEffect(() => {
        window.scroll(0,0)
    }, [])

  return (
    <div className="page-container">
      <div className="yellow-line">
        <h3>Как работает препарат?</h3>
      </div>
      <div className="page-text">
        <img 
        className="mb-ml"
        style={{float: 'right'}} 
        src={HowItWorks1} alt="" />
        <article>
          <p>
            Контрактубекс® гель обладает тройной активной формулой с эффектами,
            которые способствуют физическому заживлению рубцов и обеспечивают
            минимальную видимость шрама.
          </p>
          <strong>Формула геля Контрактубекс® обладает тройной пользой:</strong>
          <ul>
            <li>- предотвращает чрезмерное рубцевание ткани</li>
            <li>- уменьшает покраснение и зуд на коже</li>
            <li>- улучшает эластичность и мягкость кожи</li>
          </ul>
          <strong>
            Контрактубекс® содержит три взаимодополняющих активных ингредиента:
          </strong>
          <p>
            <strong>Extractum cepae -</strong> обладает противовоспалительными и
            бактерицидными свойствами и предотвращает образование избыточной
            рубцовой ткани.
          </p>
          <p>
            <strong>Heparin -</strong> смягчает структуру ткани, обладает
            противовоспалительными и противоотечными свойствами, поддерживает
            регенерацию клеток и тканей и способен связывать воду с рубцовой
            тканью.
          </p>
          <p>
            <strong>Allantoin -</strong> способствует заживлению ран и оказывает
            смягчающее действие, способствует проникновению через кожу и снимает
            зуд, часто связанный с образованием рубцов.
          </p>
          <p>
            Начните применять гель Контрактубекс® сразу после заживления раны
            и воспользуйтесь уникальным действием 3 компонентов, которые
            помогают минимизировать видимость рубцов. Курс применения может занять от 3
            до 6 месяцев в зависимости от степени рубцевания, так как рубцовая
            ткань восстанавливается очень медленно.{" "}
          </p>
          <strong>
            Будьте амбициозны и придерживайтесь курса - это стоит усилий!
          </strong>
          <p>Гель Контрактубекс® эффективно работает со всеми типами рубцов</p>
          <p>
            <strong>Раны -</strong> это повреждения кожи, которые остаются с
            нами всю жизнь. Большинство ран безобидны и быстро забываются. Тем
            не менее, они оставляют следы на нашей коже - шрамы.
          </p>
          <strong>
            Гель Контрактубекс® можно использовать после различных видов
            повреждений кожи:
          </strong>
          <ul>
            <li>-Ссадины</li>
            <li>-Порезы или рваные раны</li>
            <li>-Операции (в том числе после кесарева сечения)</li>
            <li>-Ожоги</li>
            <li>-Лазерные процедуры (например, удаление татуировок)</li>
          </ul>
          <p>
            Возникающие шрамы также разнообразны, как и виды травм. Некоторые
            шрамы едва заметны, в то время как другие могут причинять боль или
            даже вызывать физические нарушения - например, ограничение
            подвижности.
          </p>
          <strong>В целом:</strong>
          <p>
            Раннее применение геля Контрактубекс® во всех случаях гарантирует, что
            образовавшиеся шрамы заживут как можно лучше и будут в лучшем случае
            едва заметны.
          </p>
        </article>
        <a target={"_blank"} href="https://www.rceth.by/NDfiles/instr/5092_01_03_06_08_11_16_s.pdf">Инструкция</a>
      </div>
      <SectionFourth></SectionFourth>
    </div>
  );
}

export default HowItWorks;
