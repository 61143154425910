import React from 'react'
import { Link } from 'react-router-dom'
import BottleImg from '../../assets/slider-3-image.png'

function ThirdSlider() {
  return (
    
    <div className="slider-body__current-view">
        <div className="slider-body__current-view-left">
            <img src={BottleImg} alt="" />
        </div>
        <div className="slider-body__current-view-right">
            <h2>Контрактубекс® гель</h2>
            <p><strong>Помогает сделать шрам менее заметным у детей от 1 <Link to={'/how-it-works'}>года!</Link></strong></p>
            <ul>
                <li>Способствует разглаживанию шрама</li>
                <li>Способствует уменьшению покраснения и зуда</li>
                <li>Помогает предотвратить избыточное образование рубцовой ткани</li>
            </ul>
            <div className="row">
                <button onClick={() => window.scroll({top: document.querySelector('section.third').offsetTop - 100, behavior: "smooth"})}>Узнать больше</button>
                <a  className='ml-1' download href='contraktubex.pdf'>Скачать инструкцию</a>
            </div>
        </div>
    </div>
  )
}

export default ThirdSlider