import React, { FC, useEffect, useState } from "react";
import SliderNext from "../Buttons/SliderNext";
import SliderPrev from "../Buttons/SliderPrev";
import FirstSlide from "./FirstSlide";
import FourthSlide from "./FourthSlide";
import SecondSlide from "./SecondSlide";
import "./Slider.scss";
import ThirdSlider from "./ThirdSlider";

interface Props {
  isMobile: boolean;
  windowWidth: number
}

const Slider: FC<Props> = ({isMobile, windowWidth}) => {
  const sliderLength = 4;
  const [currentPosition, setCurrentPosition] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const [pointerPosition, setPointerPosition] = useState({ x1: 0, x2: -1, delay: 0 });
  const [sliderTimeout, setSliderTimeout] = useState(0)

  const slideNextHandler = () => {
    if (currentPosition >= sliderLength - 1) {
      setCurrentPosition(0);
    } else {
      setCurrentPosition((currentPosition) => currentPosition + 1);
    }
  };

  const slidePrevHandler = () => {
    if (currentPosition <= 0) {
      setCurrentPosition(sliderLength - 1);
    } else {
      setCurrentPosition((currentPosition) => currentPosition - 1);
    }
  }

  useEffect(() => {
    let timeout: any
    if (autoSlide) {
      timeout = setTimeout(() => slideNextHandler(), 5000)
      setSliderTimeout(timeout)
      return
    }
    clearTimeout(sliderTimeout)
    return(() => {
      clearTimeout(sliderTimeout)
    })
  }, [autoSlide, currentPosition]);

  const swipeHandler = () => {
    if (Math.abs(pointerPosition.delay) < 50) {
      setPointerPosition({x1: 0, x2: -1, delay: 0})
      return
    }
    if (pointerPosition.x1 - pointerPosition.x2 > 0) {
      setAutoSlide(false)
      clearTimeout(sliderTimeout)
      slideNextHandler()
      setPointerPosition({x1: 0, x2: -1, delay: 0})
    } else {
      setAutoSlide(false)
      clearTimeout(sliderTimeout)
      slidePrevHandler()
      setPointerPosition({x1: 0, x2: -1, delay: 0})
    }
  }

  return (
    <section
      className="slider"
      onClick={() => setAutoSlide(autoSlide => !autoSlide)}
      onTouchStart={(e) => {
        setAutoSlide(false)
        setPointerPosition({ x1: e.targetTouches[0].screenX, x2: -1, delay: 0 })
      }
      }
      onTouchMove={(e) => {
        setPointerPosition({
          ...pointerPosition,
          x2: e.changedTouches[0].screenX,
          delay: pointerPosition.x2 !== -1 ? 100* (pointerPosition.x1 - pointerPosition.x2) / window.innerWidth : 0
        })
      }
      }
      onTouchEnd={(e) => {
        swipeHandler()
      }}
    >
      <button
        onClick={(e) => {
          e.stopPropagation()
          slidePrevHandler();
          setAutoSlide(false);
        }}
        className="slider-button__prev slider-button"
      >
        <SliderPrev></SliderPrev>
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation()
          slideNextHandler();
          setAutoSlide(false);
        }}
        className="slider-button__next slider-button"
      >
        <SliderNext></SliderNext>
      </button>
      <div
      className="slider-current-position__wrapper">
        {new Array(sliderLength).fill(0).map((e, id) => {
          return (
            <div style={{cursor: "pointer"}} onClick={(e) => {e.stopPropagation(); setAutoSlide(false); setCurrentPosition(id)}} key={id} className="slider-current-position__element-wrapper">
              <div
                style={{ 
                  animation: currentPosition === id && !autoSlide ? 'unset' : '', 
                  width: currentPosition === id && !autoSlide ? '100%' : '',
                }}
                className={
                  currentPosition === id 
                    ? "slider-current-position__element active"
                    : "slider-current-position__element"
                }
              ></div>
            </div>
          );
        })}
      </div>
      {
        isMobile ?
        <div
          style={{ transform: pointerPosition.delay ? `translateX(${-(currentPosition * 100) + (-pointerPosition.delay)}vw)` : `translateX(-${currentPosition * 100}vw)`, transition: pointerPosition.delay ? '0s ease transform' :'1s ease transform'}}
          className="slider-body"
        >
          {sliderSlides()}
        </div>
        : 
        <div
          style={{ transform:`translateX(${-(currentPosition * (windowWidth >= 1400 ? 1400 : windowWidth))}px)`}}
          className="slider-body"
        >
          {sliderSlides()}
        </div>
      }
    </section>
  );
}

const sliderSlides = () => {
  return (
    <>
    <FirstSlide></FirstSlide>
    <SecondSlide></SecondSlide>
    <ThirdSlider></ThirdSlider>
    <FourthSlide></FourthSlide>
    {/* <div className="slider-body__current-view">5</div> */}
    </>
  )
}

export default Slider;
