import React from 'react';
import './PrivacyPolicy.scss';
import { useEffect } from 'react';

function PrivacyPolicy() {
    
  useEffect(() => {
    window.scroll(0,0)
}, [])

  return (
    <div className="page-container">
      <div className="yellow-line">
        <h3 className='custom'>Политика конфиденциальности</h3>
      </div>
      <div className="page-text">
        <article>
          <strong>Политика конфиденциальности</strong>
          <p>
            Acino, ("Acino", "мы", "нас") управляет данным
            веб-сайтом. Мы уважаем вашу конфиденциальность и хотим, чтобы вы
            понимали, как мы используем ваши личные данные. Данная Политика
            конфиденциальности описывает, когда, почему и как мы собираем и
            используем вашу информацию, когда вы посещаете наш сайт. Нажмите на
            любую тему ниже, чтобы перейти непосредственно к интересующим вас
            темам.
          </p>
          <p>
            Если у вас есть вопросы или комментарии о том, как Acino обрабатывает
            ваши данные, пожалуйста, свяжитесь с нашим сотрудником по защите
            данных по почте или электронной почте по адресу:
          </p>
          <ul>
            <li>Acino</li>
            <li>г.Минск, пр. Победителей 106</li>
            <li>офис 401 (помещение 34)</li>
            {/* <li>Защита данных</li>
            <li>Экенхаймер Ландштрассе 100</li>
            <li>60318 Франкфурт-на-Майне</li>
            <li>Германия</li> */}
          </ul>

          <a href="mailto:office_by@acino.swiss">office_by@acino.swiss</a>
          <strong>Общая информация</strong>
          <p>
            Каждый раз, когда вы заходите на этот сайт, ваш интернет-браузер
            передает нам файл, содержащий данные о вашей деятельности в
            Интернете. Мы храним эти данные в файле журнала на нашем сервере.
            Этот файл журнала содержит следующую информацию:
          </p>
          <ul>
            <li>ранее посещенный сайт ("referrer-URL")</li>
            <li>имя файла</li>
            <li>дата и время запроса</li>
            <li>объем переданных данных</li>
            <li>используемый веб-браузер</li>
            <li>используемая операционная система;</li>
            <li>
              и полный IP-адрес компьютера, с которого осуществляется доступ.
            </li>
          </ul>
          <p>
            Мы храним эти данные для повышения безопасности нашего сайта, в
            частности, для предотвращения возможных попыток атак на наш
            веб-сервер. Ваш IP-адрес удаляется максимум через семь дней, как
            только в нем отпадает необходимость для обеспечения безопасности
            сайта.
          </p>
          <p>
            Кроме того, мы можем сотрудничать с третьими лицами ("подрядчики
            Acino") для предоставления услуг для нас. При определенных
            обстоятельствах подрядчики Acino могут иметь доступ к вашим данным.
            Однако подрядчики Acino будут обрабатывать ваши данные исключительно
            для и от имени Acino и только для целей, указанных в настоящей
            политике. Кроме того, компания Acino и ее подрядчики будут защищать
            ваши данные с помощью соответствующих технических и организационных
            мер безопасности.
          </p>
          <strong>Использование файлов cookie</strong>
          <p>
            Cookies - это небольшие текстовые файлы, которые хранятся на вашем
            компьютере. Мы используем сеансовые файлы cookie для включения
            защищенных паролем разделов нашего сайта. Сеансовые файлы cookie
            позволяют вам перемещаться по защищенным разделам нашего сайта без
            необходимости повторной регистрации в каждом разделе.
          </p>
          <p>
            Мы также можем использовать файлы cookie для анонимного сбора
            информации о том, как вы перемещаетесь по нашему сайту и какие
            функции вас интересуют. Это позволяет нам улучшать наш сайт и ваш
            опыт работы в Интернете. Большинство браузеров настроены на
            автоматическое принятие файлов cookie. Вы можете в любое время
            отключить использование файлов cookie или настроить свой браузер на
            предупреждение об отправке файлов cookie. Если вы отключите cookies,
            вы, возможно, не сможете использовать некоторые или все функции
            сайта.
          </p>
          <strong>Список файлов cookie</strong>
          <p>
            Файл cookie — это небольшой фрагмент данных (текстовый файл),
            который веб-сайт при вашем посещении отправляет в браузер для
            сохранения на вашем устройстве, что позволяет запомнить информацию о
            вас, например ваш язык или информацию для входа в учетную запись.
            Эти файлы cookie настраиваем мы, и они называются основными файлами
            cookie. Мы также используем сторонние файлы cookie, которые
            настраиваются на домене другого веб-сайта (а не того веб-сайта,
            который вы посещаете) в целях ведения нашей рекламной и
            маркетинговой деятельности. В частности, мы используем файлы cookie
            и другие технологии отслеживания для следующих целей:
          </p>
          <strong>Строго необходимые файлы cookie</strong>
          <p>
            Эти файлы cookie необходимы для функционирования веб-сайта и не
            могут быть отключены в наших системах. Как правило, они активируются
            только в ответ на ваши действия, аналогичные запросу услуг, такие
            как настройка уровня конфиденциальности, вход в систему или
            заполнение форм. Вы можете настроить браузер таким образом, чтобы он
            блокировал эти файлы cookie или уведомлял вас об их использовании,
            но в таком случае возможно, что некоторые разделы веб-сайта не будут
            работать.
          </p>
          <table>
            <thead>
              <tr>
                <td>Подгруппа файлов cookie</td>
                <td>Файлы cookie</td>
                <td>Используемые файлы cookie</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                    <strong className="show-on-mobile">Подгруппа файлов cookie</strong>
                  <a target={'_blank'} href="http://www.contractubex.com">
                    contractubex.com
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Файлы cookie</strong>
                  <a
                    target={'_blank'}
                    href="https://cookiepedia.co.uk/cookies/OptanonConsent"
                  >
                    OptanonConsent
                  </a>
                  ,{' '}
                  <a
                    target={'_blank'}
                    href="https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed"
                  >
                    OptanonAlertBoxClosed
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Используемые файлы cookie</strong>
                    1st Party</td>
              </tr>
              <tr>
                <td>
                    <strong className="show-on-mobile">Подгруппа файлов cookie</strong>
                  <a target={'_blank'} href="http://onetrust.com">
                    onetrust.com
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Файлы cookie</strong>
                    __cfduid</td>
                <td>
                    <strong className="show-on-mobile">Используемые файлы cookie</strong>
                    3rd Party</td>
              </tr>
            </tbody>
          </table>
          <strong>Эксплуатационные файлы cookie</strong>
          <p>
            Эти файлы cookie позволяют нам подсчитывать количество посещений и
            источников трафика, чтобы оценивать и улучшать работу нашего
            веб-сайта. Благодаря им мы знаем, какие страницы являются наиболее и
            наименее популярными, и видим, каким образом посетители перемещаются
            по веб-сайту. Все данные, собираемые при помощи этих cookie,
            группируются в статистику, а значит, являются анонимными. Если вы не
            одобрите использование этих файлов cookie, у нас не будет данных о
            посещении вами нашего веб-сайта.
          </p>
          <table>
            <thead>
              <tr>
                <td>Подгруппа файлов cookie</td>
                <td>Файлы cookie</td>
                <td>Используемые файлы cookie</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                    <strong className="show-on-mobile">Подгруппа файлов cookie</strong>
                  <a target={'_blank'} href="http://contractubex.com">
                    contractubex.com
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Файлы cookie</strong>
                  <a
                    target={'_blank'}
                    href="https://cookiepedia.co.uk/cookies/_gid"
                  >
                    _gid
                  </a>
                  ,{' '}
                  <a
                    target={'_blank'}
                    href="https://cookiepedia.co.uk/cookies/_ga"
                  >
                    _ga
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Используемые файлы cookie</strong>
                    1st Party</td>
              </tr>
            </tbody>
          </table>
          <strong>Целевые файлы cookie</strong>
          <p>
            Эти файлы cookie настраиваются через наш веб-сайт нашими партнерами
            по рекламе. Они могут использоваться этими компаниями для сбора
            данных о ваших интересах и отображения для вас актуальной рекламы на
            других веб-сайтах. Они работают посредством уникальной идентификации
            вашего браузера и устройства. Если вы не одобрите использование этих
            файлов cookie, вам не будет демонстрироваться наша адресная реклама
            на различных веб-сайтах.
          </p>
          <table>
            <thead>
              <tr>
                <td>Подгруппа файлов cookie</td>
                <td>Файлы cookie</td>
                <td>Используемые файлы cookie</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                    <strong className="show-on-mobile">Подгруппа файлов cookie</strong>
                  <a target={'_blank'} href="http://contractubex.com">
                    contractubex.com
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Файлы cookie</strong>
                  <a
                    target={'_blank'}
                    href="https://cookiepedia.co.uk/cookies/_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx"
                  >
                    _gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx
                  </a>
                  ,{' '}
                  <a
                    target={'_blank'}
                    href="https://cookiepedia.co.uk/cookies/_gat_gtag_"
                  >
                    _gat_gtag_
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Используемые файлы cookie</strong>
                    1st Party</td>
              </tr>
              <tr>
                <td>
                    <strong className="show-on-mobile">Подгруппа файлов cookie</strong>
                  <a
                    target={'_blank'}
                    href="https://cookiepedia.co.uk/host/doubleclick.net"
                  >
                    doubleclick.net
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Файлы cookie</strong>
                    test_cookie, IDE</td>
                <td>
                    <strong className="show-on-mobile">Используемые файлы cookie</strong>
                    3rd Party</td>
              </tr>
              <tr>
                <td>
                    
                <strong className="show-on-mobile">Подгруппа файлов cookie</strong>
                  <a
                    target={'_blank'}
                    href="https://cookiepedia.co.uk/host/youtube.com"
                  >
                    youtube.com
                  </a>
                </td>
                <td>
                    <strong className="show-on-mobile">Файлы cookie</strong>
                    VISITOR_INFO1_LIVE, GPS, YSC</td>
                <td>
                    <strong className="show-on-mobile">Используемые файлы cookie</strong>
                    3rd Party</td>
              </tr>
            </tbody>
          </table>
          <strong>Использование Google Analytics</strong>
          <p>
            На этом сайте используется Google Analytics, служба веб-аналитики,
            предоставляемая компанией Google Inc. ("Google"). Acino использует
            Google Analytics, чтобы понять, как посетители используют наш сайт.
            Данные cookie об использовании вами этого сайта (включая ваш
            IP-адрес) передаются на сервер Google в США и хранятся там. Мы
            используем анонимизированное приложение Google Analytics, которое
            усекает ваш IP-адрес. Другими словами, Google сокращает ваш IP-адрес
            перед его передачей в США. Google использует эту информацию для
            оценки использования вами данного сайта, составления отчетов о
            деятельности сайта и предоставления других услуг. Google также может
            передавать эту информацию третьим лицам, если это требуется по
            закону или если такие третьи лица обрабатывают эту информацию от
            имени Google. IP-адрес, переданный вашим браузером через Google
            Analytics, не будет связан с другими данными, хранящимися в Google.
          </p>
          <p>
            Вы можете предотвратить сохранение файлов cookie, выбрав
            соответствующие настройки в программном обеспечении вашего браузера.
            Однако обратите внимание, что в этом случае вы не сможете в полной
            мере использовать все функции данного сайта.
          </p>
          <p>
            Вы можете в любое время отказаться от сбора и хранения данных Google
            с будущим эффектом, загрузив и установив дополнение для деактивации
            для вашего браузера. Это не позволит Google Analytics собирать и
            обрабатывать данные о ваших посещениях сайта. Для получения
            дополнительной информации и инструкций по загрузке и установке
            перейдите по адресу{' '}
            <a
              target={'_blank'}
              href="http://tools.google.com/dlpage/gaoptout?hl=de"
            >
              http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            , где вы также можете скачать дополнение для деактивации.
          </p>
          <p>
            Вы также можете предотвратить сбор данных Google Analytics, перейдя
            по ссылке ниже. Будет установлен файл cookie, который предотвратит
            сбор ваших данных в будущем при посещении этого сайта:
          </p>
          <a href="https://www.pantogar.com/privacy-policy/#">
            Отключить Google Analytics
          </a>
          <strong>Доступ к видеороликам YouTube</strong>
          <p>
            On our website you can watch embedded YouTube videos, (a product of
            Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
            USA). To this end, YouTube‘s extended data protection mode is used,
            so that data can only be collected from you by YouTube if you click
            on the clip. By clicking on the clips, you declare your agreement
            with the processing of data collected about you by YouTube. To find
            out about the site policy, go to{' '}
            <a
              href="https://www.google.com/intl/de/policies/privacy/"
              target={'_blank'}
            >
              https://www.google.com/intl/de/policies/privacy/
            </a>
            .
          </p>
          <strong>
            Ссылки на другие веб-сайты или приложения для загрузки
          </strong>
          <p>
            Where our website contains links to other Acino Group websites (e.g.
            expert group website) or websites belonging to third-party
            companies, or provides apps to download, this privacy policy does
            not apply. Please see those websites or apps for information about
            how they manage your personal data.
          </p>
          <strong>Ваши права</strong>
          <p>
            Согласно Общему регламенту ЕС о защите данных ("GDPR"), вы имеете
            право запросить копию своих персональных данных, хранящихся в
            компании Acino или у подрядчиков Acino (статья 15 GDPR). Мы просим вас
            направлять такие запросы в письменной форме с указанием вашего
            имени, адреса и номера телефона, чтобы мы могли подтвердить вашу
            личность. Вы также имеете право потребовать исправления (Статья 16
            GDPR) или удаления (Статья 17 GDPR) ваших персональных данных,
            хранящихся в компании Acino. Вы можете связаться с сотрудником по
            защите данных компании Acino по адресу:
          </p>
          <ul>
            <li>Acino</li>
            <li>г.Минск, пр. Победителей 106</li>
            <li>офис 401 (помещение 34)</li>
            {/* <li>Защита данных</li>
            <li>Экенхаймер Ландштрассе 100</li>
            <li>60318 Франкфурт-на-Майне</li>
            <li>Германия</li> */}
          </ul>
          <a href="mailto:office_by@acino.swiss">office_by@acino.swiss</a>
          <p>
            Если у вас есть подозрение, что Acino не соблюдает GDPR, вы можете
            подать жалобу в орган по защите данных.
          </p>
          <strong>
            Право на возражение против обработки персональных данных
          </strong>
          <p>
            Если вы недовольны тем, как компания Acino обрабатывает ваши
            персональные данные, вы можете в любое время возразить против
            обработки ваших персональных данных (статья 21 GDPR). Вы можете
            направить свою озабоченность по поводу обработки ваших персональных
            данных сотруднику по защите данных компании Acino по указанному выше
            адресу или адресу электронной почты. Мы просим вас направлять такие
            опасения (или возражения) в письменной форме с указанием вашего
            имени, адреса и номера телефона, чтобы мы могли подтвердить вашу
            личность.
          </p>
        </article>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
