import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header/Header";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import Footer from "./components/Footer/Footer";
import Burger from "./components/Burger/Burger";
import AboutPage from "./pages/AboutPage/AboutPage";
import HowItWorks from "./pages/HowItWorks/HowItWorks";
import ScarInChildren from "./pages/ScarInChildren/ScarInChildren";
import Cookie from "./components/Cookie/Cookie";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import SitemapPage from './pages/SitemapPage/SitemapPage';

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isMobile, setIsMobile] = useState(!(window.innerWidth > 576))
  const [isBurgerOpen, setIsBurgerOpen] = useState(false)

  window.onresize = () => {
      setWindowWidth(window.innerWidth)
      if (window.innerWidth > 576) {
        isMobile === true && setIsMobile(false)
      } else {
        isMobile === false && setIsMobile(true)
      }
  }

  const toggleBurger = () => {
    setIsBurgerOpen(isBurgerOpen => !isBurgerOpen)
  }

  useEffect(() => {
    if (!isMobile) {
      
      document.body.style.overflow = 'initial'
      return
    }
    if (isBurgerOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }, [isBurgerOpen, windowWidth])

  return (
    <div className="App">
      <BrowserRouter>
        <Burger isMobile={isMobile} toggleBurger={toggleBurger} isBurgerOpen={isBurgerOpen}></Burger>
        <Header isMobile={isMobile} toggleBurger={toggleBurger} isBurgerOpen={isBurgerOpen}></Header>
        <Cookie></Cookie>
        <Routes>
          <Route path="/" element={<MainPage windowWidth={windowWidth} isMobile={isMobile}></MainPage>}></Route>
          <Route path="/about" element={<AboutPage/>}></Route>
          <Route path="/how-it-works" element={<HowItWorks/>}></Route>
          <Route path="/scars-in-children" element={<ScarInChildren/>}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
          <Route path="/terms-of-use" element={<TermsOfUse/>}></Route>
          <Route path="/sitemap" element={<SitemapPage/>}></Route>
          <Route path="*" element={<div>not found</div>}></Route>
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
