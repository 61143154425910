import React, { FC, useEffect } from "react";
import SectionFirst from "../../components/SectionFirst/SectionFirst";
import SectionFourth from "../../components/SectionFourth/SectionFourth";
import SectionSecond from "../../components/SectionSecond/SectionSecond";
import SectionThird from "../../components/SectionThird/SectionThird";
import Slider from "../../components/Slider/Slider";
import Acino from '../../assets/acino.png'
import "./MainPage.scss";

interface Props {
  isMobile: boolean;
  windowWidth: number;
}

const MainPage: FC<Props> = ({ isMobile, windowWidth }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="page-container">
      <Slider windowWidth={windowWidth} isMobile={isMobile}></Slider>
      <SectionFirst></SectionFirst>
      <SectionSecond></SectionSecond>
      <SectionThird></SectionThird>
      <SectionFourth></SectionFourth>
      <section className="fifth">
      <a className="big-link" target={"_blank"} href={"https://acino.by"}>
        <img src={Acino} alt="" />
      </a>
      </section>
    </div>
  );
};

export default MainPage;
