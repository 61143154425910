import React from "react";
import "./SectionSecond.scss";
import SuccessImg from "../../assets/success.png";
import BottleImg from '../../assets/bottle.png'
import GirlWomanImg from '../../assets/girl-woman.png'
import { Link } from "react-router-dom";

function SectionSecond() {
  return (
    <section className="second">
    <div className="section-top"></div>
      <div className="section-body">
        <div className="section-body__left">
          <h3>Как работает <Link to={'/how-it-works'}>Контрактубекс®?</Link></h3>
          <p className="p-strong">
          Активные ингредиенты геля проникают в рубцовую ткань
и помогают уменьшить образование рубца изнутри.
          </p>
          <p>
          Контрактубекс® содержит три взаимодополняющих активных ингредиента:
          <br></br>
<strong>Extractum cepae -</strong> обладает противовоспалительными и бактерицидными свойствами и
предотвращает образование избыточной рубцовой ткани.<br></br>
<strong>Heparin -</strong> смягчает структуру ткани, обладает противовоспалительными и
противоотечными свойствами, поддерживает регенерацию клеток и тканей и способен
связывать воду с рубцовой тканью.<br></br>
<strong>Allantoin -</strong> способствует заживлению ран и оказывает смягчающее действие, способствует
проникновению через кожу и снимает зуд, часто связанный с образованием рубцов.
          </p>
          <div className="info-box">
            <img src={SuccessImg} alt="" />
            <p>
              <strong>Результат</strong> - практически невидимый шрам
            </p>
          </div>
        </div>
        <div className="section-body__right">
          <div className="section-body__right-text">
            <h4>Контрактубекс® гель – препарат №1 для
лечения шрамов, который оказывает
тройное активное действие против шрамов:</h4>
            <ul>
              <li>предупреждает избыточное образование рубцовой ткани</li>
              <li>уменьшает покраснение, зуд, чувство натяжения</li>
              <li>способствует разглаживанию шрама</li>
            </ul>
          </div>
          <img className="girl-woman" src={GirlWomanImg} alt="" />
        </div>
      </div>
      <div className="section-bottom"></div>
    </section>
  );
}

export default SectionSecond;
