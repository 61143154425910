import React, { FC, ReactElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LocationImg from '../../assets/location-white.svg'
import "./Burger.scss";

const Burger = ({ isBurgerOpen, toggleBurger, isMobile }) => {

  const navigate = useNavigate()

  return (
    isMobile &&
    <menu className={isBurgerOpen ? "burger-wrapper isOpen" : "burger-wrapper"}>
      <div className="burger-inner">
        <nav>
          <ul>
            <Link to={""} onClick={() => toggleBurger()}>
              <li>Главная</li>
              <div className="hover-block"></div>
            </Link>
            <Link to={"/about"} onClick={() => toggleBurger()}>
              <li>О препарате</li>
              <div className="hover-block"></div>
            </Link>
            <Link to={"/how-it-works"} onClick={() => toggleBurger()}>
              <li>Как работает препарат?</li>
              <div className="hover-block"></div>
            </Link>
            <Link to={"/scars-in-children"} onClick={() => toggleBurger()}>
              <li>Шрамы у детей</li>
              <div className="hover-block"></div>
            </Link>
            <Link to={'/privacy-policy'} onClick={() => toggleBurger()}><li>Политика конфиденциальности</li><div className="hover-block"></div></Link>
            <Link to={'/terms-of-use'} onClick={() => toggleBurger()}><li>Условия использования</li><div className="hover-block"></div></Link>
            <Link to={'/sitemap'} onClick={() => toggleBurger()}><li>Карта сайта</li><div className="hover-block"></div></Link>
            {/* <Link to={""} onClick={() => toggleBurger()}>
              <li>Уход за кожей</li>
              <div className="hover-block"></div>
            </Link>
            <Link to={""} onClick={() => toggleBurger()}>
              <li>Вопрос-ответ</li>
              <div className="hover-block"></div>
            </Link> */}
            <button
              onClick={() => {
                toggleBurger()
                if (!document.querySelector("section.fourth")) navigate('/')
                setTimeout(() => {
                  window.scroll({
                    top: document.querySelector("section.fourth").offsetTop,
                    behavior: "smooth",
                  })
                }, 0)
              }
              }
            >
              <li className="location-link location">
                <img src={LocationImg} alt="" />
                <span>Где купить?</span>
              </li>
            </button>
            {/* <Link to={""} onClick={() => toggleBurger()}>
              <li className="test-button">
                Пройти тест
              </li>
            </Link> */}
          </ul>
        </nav>
      </div>
    </menu>

  );
};

export default Burger;
