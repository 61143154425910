import React from 'react'
import { Link } from 'react-router-dom'
import './Cookie.scss'
import { useState } from 'react';
import { useEffect } from 'react';

function Cookie() {

    const [isOpen, setIsOpen] = useState(true)
    
    const acceptHandler = () => {
        localStorage.setItem('accept_cookies', true)
        setIsOpen(false)
    }

    const dismissHandler = () => {
        localStorage.setItem('accept_cookies', false)
        setIsOpen(false)
    }

    useEffect(() => {
        const acceptCookies = localStorage.getItem('accept_cookies')
        if (acceptCookies) {
            setIsOpen(false)
        }
    }, [])

  return (
    isOpen ?
    <div className='cookie-wrapper'>
        <div className="cookie-inner">
            <p>Мы используем файлы cookie, чтобы обеспечивать правильную работу
нашего веб-сайта, персонализировать рекламные объявления и другие
материалы, обеспечивать работу функций социальных сетей и
анализировать сетевой трафик. Мы также предоставляем информацию
об использовании вами нашего веб-сайта своим партнерам по
социальным сетям, рекламе и аналитическим системам. <Link to={'/privacy-policy'}>Политика конфиденциальности</Link></p>
            <div className="row">
                <button onClick={() => dismissHandler()} className='filled'>Отклонить все</button>
                <button onClick={() => acceptHandler()} className='filled'>Принять все Cookies</button>
            </div>
        </div>
    </div>
    : <div></div>
  )
}

export default Cookie