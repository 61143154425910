import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";
import LocationImg from "../../assets/location.svg";
import Burger from "../Burger/Burger";
import Menu from "../Menu/Menu";

// interface Props {
//   toggleBurger: Function;
//   isBurgerOpen: boolean;
//   isMobile: boolean
// }

function Header({ toggleBurger, isBurgerOpen, isMobile }) {
  const navigate = useNavigate()

  return (
    <div className={isBurgerOpen ? "header-wrapper isOpen" : "header-wrapper"}>
      <header>
        <Menu toggleBurger={toggleBurger} isBurgerOpen={isBurgerOpen}></Menu>
        <div className="header-left">
          <button onClick={() => toggleBurger()} className="burger-button">
            <>
              <span></span>
              <span></span>
              <span></span>
            </>
          </button>
          {!isMobile && <p>Меню</p>}
        </div>
        <div className="header-right">
          <h1>Контрактубекс®</h1>
          <div className="header-right__buttons">
            <button
              onClick={() => {
                if (!document.querySelector("section.fourth")) navigate('/')
                setTimeout(() => {
                  window.scroll({
                    top: document.querySelector("section.fourth").offsetTop,
                    behavior: "smooth",
                  })
                }, 0)
              }
              }
            >
              <div className="location-link">
                <img src={LocationImg} alt="" />
                <span>Где купить?</span>
              </div>
            </button>
            {/* <Link to={""} className="common-button">
              Пройти тест
            </Link> */}
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
