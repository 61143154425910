import React from "react";
import { Link } from "react-router-dom";
import Link1 from '../../assets/103.png'
import Link2 from '../../assets/tabletka.png'
import "./SectionFourth.scss";

function SectionFourth() {
  return (
    <section className="fourth">
      <div className="section-title">
        <h4>Где купить?</h4>
      </div>
      <div className="section-body">
        <div className="big-links-row">
            <a target={'_blank'} href={'https://apteka.103.by/kontraktubeks/'}>
                <img src={Link1} alt="" />
            </a>
            <a target={'_blank'} href={'https://tabletka.by/result?ls=4876'}>
                <img src={Link2} alt="" />
            </a>
        </div>
      </div>
    </section>
  );
}

export default SectionFourth;
