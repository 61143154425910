import React from "react";
import { Link } from "react-router-dom";
import BottleImg from "../../assets/bottle.png";

function FirstSlide() {
  return (
    <div className="slider-body__current-view">
      <div className="slider-body__current-view-left">
        <img src={BottleImg} alt="" />
      </div>
      <div className="slider-body__current-view-right">
        <h2>Контрактубекс® гель</h2>
        <p>
          <strong>Препарат для уменьшения следов шрамов</strong>
          <br></br> с тройным активным <Link to={'/how-it-works'}>действием</Link>
        </p>
        <ul>
          <li>Способствует разглаживанию шрама</li>
          <li>Способствует уменьшению покраснения и зуда</li>
          <li>Помогает предотвратить избыточное образование рубцовой ткани</li>
        </ul>
        <div className="row">
          <button
            onClick={() =>
              window.scroll({
                top: document.querySelector("section.third").offsetTop - 100,
                behavior: "smooth",
              })
            }
          >
            Узнать больше
          </button>
          <a className="ml-1" download href="contraktubex.pdf">
            Скачать инструкцию
          </a>
        </div>
      </div>
    </div>
  );
}

export default FirstSlide;
