import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SitemapPage.scss';

function SitemapPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="page-container">
      <div className="yellow-line">
        <h3>Карта сайта</h3>
      </div>
      <div className="page-text">
        <article>
            <ul>
                <li>
                    <Link to={'/'}>Главная</Link>
                </li>
                <ul>
                    <li>
                        <Link to={'/about'}>
                            О препарате
                        </Link>
                    </li>
                    <li>
                        <Link to={'/how-it-works'}>
                            Как это работает?
                        </Link>
                    </li>
                    <li>
                        <Link to={'/scars-in-children'}>
                            Шрамы у детей
                        </Link>
                    </li>
                    <li>
                        <Link to={'/privacy-policy'}>
                            Политика конфиденциальности
                        </Link>
                    </li>
                    <li>
                        <Link to={'/terms-of-use'}>
                            Условия использования
                        </Link>
                    </li>
                    <li>
                        <Link to={'/sitemap'}>
                            Карта сайта
                        </Link>
                    </li>
                </ul>
            </ul>
        </article>
      </div>
    </div>
  );
}

export default SitemapPage;
