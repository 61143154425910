import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.scss';
import Arrow from '../../assets/arrow-s-e.svg';
import LocationImg from '../../assets/location.svg';
import Acinoimg from '../../assets/acino_footer.png'

function Footer() {

  const navigate = useNavigate()
  return (
    <footer>
      <div className="footer-top"></div>
      <div className="footer-body__wrapper">
        <div className="footer-body">
          <div className="footer-body__left">
            <div className="row">
              <img className="logo" src={Acinoimg} alt="" />
              <p className="small">
                Препарат для лечения шрамов с тройным активным действием
              </p>
            </div>
            <p className="warning">
              ИМЕЮТСЯ ПРОТИВОПОКАЗАНИЯ. НЕОБХОДИМО ОЗНАКОМИТЬСЯ С ИНСТРУКЦИЕЙ ПО
              ПРИМЕНЕНИЮ.
            </p>
            <p className="warning">BY-CONT-DIC-102022-019</p>
            <a className="outlined" target={'_blank'} href={'https://acino.by/#contacts'}>
              Фармаконадзор <img src={Arrow} alt="" />
            </a>
          </div>
          <div className="footer-body__right">
            <span>Меню</span>
            <ul>
              <Link to={''}>
                <li>Главная</li>
              </Link>
              <Link to={'/about'}>
                <li>О препарате</li>
              </Link>
              <Link to={'/how-it-works'}>
                <li>Как работает препарат?</li>
              </Link>
              <Link to={'/scars-in-children'}>
                <li>Шрамы у детей</li>
              </Link>
              {/* <Link to={""}>
                <li>Уход за кожей</li>
              </Link>
              <Link to={""}>
                <li>Вопрос-Ответ</li>
              </Link> */}

              <Link to={'/privacy-policy'}><li>Политика конфиденциальности</li></Link>
              <Link to={'/terms-of-use'}><li>Условия использования сайта</li></Link>
              <Link to={'/sitemap'}><li>Карта сайта</li></Link>
              <button
              onClick={() => {
                if (!document.querySelector("section.fourth")) navigate('/')
                setTimeout(() => {
                  window.scroll({
                    top: document.querySelector("section.fourth").offsetTop,
                    behavior: "smooth",
                  })
                }, 0)
              }
              }
            >
              <div className="location-link">
                <img src={LocationImg} alt="" />
                <span>Где купить?</span>
              </div>
            </button>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
