import React from "react";
import "./SectionFirst.scss";
import BottleImg from "../../assets/bottle.png";
import HandsImg from "../../assets/hands.png";
import LocationImg from "../../assets/location.svg";
import { Link } from "react-router-dom";

function SectionFirst() {
  return (
      <section className="first">
        <div className="section-top"></div>
        <div className="section-body">
          <div className="section-body__left">
            <img className="hands" src={HandsImg} alt="" />
          </div>
          <div className="section-body__right">
            <Link onClick={() => window.scroll({top: document.querySelector('section.fourth').offsetTop - 100, behavior: "smooth"})} to={''}>
              <div className="location-link">
                <img src={LocationImg} alt="" />
                <span>Где купить?</span>
              </div>
            </Link>
            <h2>Контрактубекс® имеет уникальную формулу, 
которая способствует сглаживанию
шрамов и обеспечивает минимальную
видимость рубцов.</h2>
            <p>Формула геля Контрактубекс® обладает тройной пользой:</p>
            <ul>
              <li>Предотвращает чрезмерное рубцевание кожи</li>
              <li>Уменьшает покраснение и зуд</li>
              <li>
                Улучшает эластичность и мягкость кожи
              </li>
            </ul>
            <div className="row">
              <a target={"_blank"} href={"https://acino.by"} 
              // onClick={() => window.scroll({top: document.querySelector('section.third').offsetTop - 100, behavior: "smooth"})} 
              className="filled">
                Подробнее
              </a>
              <a download href='contraktubex.pdf' className="outlined">
                Скачать инструкцию
              </a>
            </div>
            <i className="small-text">1. Willital GH, Simon J. Effi cacy of Early Initiation of a Gel Containing Extractum Cepae, Heparin,_ and Allantoin for Scar
Treatment: An Observational,
Noninterventional Study of Daily Practice. J Drugs Dermatol 013;12:38–42.
2. Montanari M. et al., Poster Overnight Intensive Patch for Scar Management, IMCAS 2016.
3. Из официальной инструкции по применению препарата Контрактубекс®
</i>
          </div>
        </div>
      </section>
  );
}

export default SectionFirst;
