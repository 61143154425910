import React from "react";
import "./SectionThird.scss";
import BottleImg from "../../assets/bottle.png";
import LightImg from "../../assets/light-1.png";

function SectionThird() {
  return (
    <section className="third">
      <div className="section-title">
        <h4>Как использовать?</h4>
      </div>
      <div className="section-body">
        <div className="bottle-wrapper">
          <img className="light" src={LightImg} alt="" />
          <img className="bottle" src={BottleImg} alt="" />
        </div>
        <div className="circles-wrapper">
          <div className="circle circle-1">
            <h4 className="circle-title">Начало применения</h4>
            <p className="circle-info">
              Сразу после заживления раны
            </p>
          </div>
          <div className="circle circle-2">
            <h4 className="circle-title">Для свежих рубцов </h4>
            <p className="circle-info">
            Наносить 2-3 раза в день необходимое количество геля и осторожно втирать в рубец до полного впитывания в течение минимум 4 недель 
            </p>
          </div>
          <div className="circle circle-3">
            <h4 className="circle-title">Для застарелых рубцов </h4>
            <p className="circle-info">
            Наносить 3 раза в сутки массирующими движениями. Для усиления эффекта можно оставить гель под повязкой на ночь. Срок лечения в таком случае составит не менее 6 месяцев. Также возможно совмещение лечения с процедурами по рекомендации врача.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionThird;
