import React, {useEffect} from "react";
import SectionFourth from "../../components/SectionFourth/SectionFourth";
import BottleImg from "../../assets/bottle.png";
import "./AboutPage.scss";

function AboutPage() {

  useEffect(() => {
      window.scroll(0,0)
  }, [])

  return (
    <div className="page-container">
      <div className="yellow-line">
        <h3>О препарате</h3>
      </div>
      <div className="page-text">
        <article>
        <img 
        className="mb"
        style={{float: 'right'}} 
        src={BottleImg} alt="" />
          <p>
            Контрактубекс® гель успешно применяется при борьбе с рубцами уже более 50 лет и продается более чем в 60 странах, его регулярно рекомендуют врачи по всему миру.
            Эффективность геля Контрактубекс® была доказана в ходе
            многочисленных клинических исследований. Уникальная комбинация трех
            активных ингредиентов действует глубоко в рубце, уменьшая рубцы
            изнутри. <sup>1, 2</sup>
            <br/>
          <small>
          <a target={"_blank"} href="https://www.rceth.by/NDfiles/instr/5092_01_03_06_08_11_16_s.pdf">1. Из официальной инструкции по применению препарата Контрактубекс®</a>
          <br/>
          <a target={"_blank"} href="https://jddonline.com/issue/?issue_id=104">2.Willital et al. 2013 Journal of Drugs in Dermatology Volume 12, Issue 1,55-59</a>
</small>
          </p>
          <p>
            <strong>Результат:</strong> более незаметный шрам.
          </p>
          <p>
            Эффективность геля Контрактубекс® также отражается в высокой
            удовлетворенности пациентов. Положительный опыт тысяч пациентов
            показывает, что использование геля Контрактубекс® действительно
            имеет значение, и шрамы можно успешно устранять. <sup>3, 4</sup> 
            <br></br>
          <small>
          <a target={"_blank"} href="https://jddonline.com/issue/?issue_id=104">3.Willital et al. 2013 Journal of Drugs in Dermatology Volume 12, Issue 1,55-59</a>
          <br></br>
          <a target={"_blank"} href="https://www.desitin.cz/wp-content/uploads/2021/11/Contractubex-Koc.pdf">4. Koc E. et al. 2008; Dermatol Surg 2008; 34:1-8</a>
</small>
          </p>
          <p>Гель Контрактубекс® можно применять при всех типах рубцов.</p>
          <p>
            <strong>Раны -</strong> это повреждения кожи, которые остаются всю жизнь. Большинство ран безобидны, быстро заживают и забываются. Однако, даже незначительные повреждения могут оставлять следы на нашей коже - шрамы.
          </p>
          <strong>
            Гель Контрактубекс® можно использовать после различных видов
            повреждений кожи:
          </strong>
          <ul>
            <li>-Ссадины, порезы или рваные раны;</li>
            <li>-Операции (в том числе после кесарева сечения);</li>
            <li>-Ожоги;</li>
            <li>-Лазерные процедуры (например, удаление татуировок);</li>
          </ul>
          <p>
            Возникающие шрамы также разнообразны, как и виды травм. Некоторые
            шрамы едва заметны, в то время как другие могут причинять боль или
            даже вызывать физические нарушения - например, ограничение
            подвижности.
          </p>
          <p>
          В целом раннее применениелечение геляем Контрактубекс® во всех случаях приводит к тому, что образовавшиеся шрамы заживут как можно лучше и будут в лучшем случае едва заметны.
          </p>
          <p>
            Вы можете начать использовать Контрактубекс® гель сразу после
            заживления раны или снятия швов, потому что чем раньше вы начнете
            лечение рубцов, тем больше шансов положительно повлиять на их
            развитие. Покраснение исчезает уже через несколько недель, а
            продолжение лечения препаратам с его формулой тройного действия
            позволяет добиться дальнейшего улучшения внешнего вида. Шрам
            становится более эластичным и менее заметным. Лечение может занять
            от 3 до 6 месяцев в зависимости от степени рубцевания, поскольку
            рубцовая ткань восстанавливается очень медленно.
          </p>
          <strong>
          Будьте решительны и придерживайтесь курса – результаты того стоят!
          </strong>
        </article>
      </div>
      <SectionFourth></SectionFourth>
    </div>
  );
}

export default AboutPage;
