import React from 'react';
import { useEffect } from 'react';

function TermsOfUse() {

    
  useEffect(() => {
    window.scroll(0,0)
}, [])

  return (
    <div className="page-container">
      <div className="yellow-line">
        <h3>Условия использования</h3>
      </div>
      <div className="page-text">
        <article>
          <strong>Условия использования</strong>
          <p>
            Acino оставляет за собой право вносить дополнения, удаления или
            изменения в информацию в любое время без предварительного
            уведомления. Положения Условий использования не создают никаких
            договорных или иных юридических прав у какой-либо стороны или от ее
            имени. Никаких гарантий и обязательств
          </p>
          <p>
            Acino стремится поддерживать сайт в актуальном состоянии. Однако Acino
            не гарантирует точность и полноту любой информации, содержащейся на
            этом сайте. За исключением прямого ущерба, вызванного умышленными
            действиями или грубой халатностью компании Acino, Acino не несет
            ответственности за любой прямой, косвенный, специальный, штрафной,
            случайный или иной косвенный ущерб, возникший в результате или в
            связи с посещением вами данного сайта, включая, без ограничений,
            упущенную выгоду, перерывы в работе, потерю программ или электронных
            данных.
          </p>
          <p>
            Для удобства наших посетителей Acino предоставляет ссылки на другие
            веб-сайты. Однако некоторые ссылки на этом сайте ведут на ресурсы,
            расположенные на серверах третьих лиц, над которыми Acino не имеет
            контроля. Acino не несет ответственности за контент третьих лиц или
            последствия его использования.
          </p>
          <strong>Экспертная консультация или мнение</strong>
          <p>
            В качестве полезного ресурса для наших посетителей этот сайт может
            содержать мнения экспертов. Информация на этом сайте,
            идентифицированная как мнение эксперта или доступная с этого сайта
            по гиперссылке, представляет собой мнение соответствующих экспертов,
            которое не обязательно совпадает с мнением Acino.
          </p>
          <strong>Интеллектуальная собственность</strong>
          <p>
            Пожалуйста, обратите внимание, что политика компании Acino
            заключается в обеспечении соблюдения прав интеллектуальной
            собственности в максимальном объеме, предусмотренном действующим
            законодательством, включая уголовное наказание. Торговые марки,
            логотипы и знаки обслуживания (далее "Торговые марки"),
            представленные на данном сайте, являются Торговыми марками,
            принадлежащими Acino или имеющими лицензию на их использование. Ничто
            на этом сайте не должно толковаться как предоставление, косвенное
            или иное, какой-либо лицензии или права на Товарные знаки без
            прямого письменного разрешения Acino или, если не Acino, владельца
            Товарного знака. Кроме того, любое воспроизведение, ретрансляция,
            изменение или использование содержимого данного сайта в иных целях,
            кроме личных, некоммерческих, требует предварительного письменного
            согласия Acino. Следующее уведомление об авторском праве должно
            присутствовать на каждой копии содержимого данного сайта или его
            части: Copyright © 2022 Acino. Все права защищены.
          </p>
        </article>
      </div>
    </div>
  );
}

export default TermsOfUse;
